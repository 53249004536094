import gql from 'graphql-tag';
import { apolloClient as apollo } from '@/vue-apollo';
import { isNil } from '~utils';
import _ from 'lodash';

/**
 * get all scripts
 */
export const getScripts = async (licenceId = 0, projectId = 0, typeId = -1, showAllLicense = false) => {
	let variables = null;
	const query = gql`
        query ($licenceId: ID, $projectId: ID, $typeId: ID, $showAllLicense: Boolean) {
            GetScriptList(LicenceId: $licenceId, ProjectId: $projectId, ScriptType: $typeId, GetLicenseScript: $showAllLicense) ${columns}
        }
    `;

	let records = [];
	if (
		(isNil(licenceId) || licenceId === '' || parseInt(licenceId, 10) === 0) &&
		(isNil(projectId) || projectId === '' || parseInt(projectId, 10) === 0) &&
		(isNil(typeId) || typeId === '' || parseInt(typeId, 10) === -1) &&
		(isNil(showAllLicense) || showAllLicense === false)
	) {
		records = await apollo.query({
			query,
			fetchPolicy: 'no-cache'
		});
	} else {
		variables = {
			licenceId: isNil(licenceId) ? null : parseInt(licenceId, 10),
			projectId: parseInt(projectId, 10),
			typeId: parseInt(typeId, 10),
			showAllLicense
		};

		records = await apollo.query({
			query,
			variables,
			fetchPolicy: 'no-cache'
		});
	}

	return records.data.GetScriptList;
};

/**
 * get one script
 * @param {String, Number} scriptId
 */
export const getScript = async (scriptId = 0) => {
	const {
		data: { GetScriptRecorder }
	} = await apollo.query({
		query: gql`
			query ($scriptId: ID!) {
				GetScriptRecorder(ScriptRecorderId: $scriptId) ${columns}
		`,
		variables: {
			scriptId: parseInt(scriptId, 10)
		},
		fetchPolicy: 'no-cache'
	});

	return GetScriptRecorder;
};

/**
 * add script
 * @param {Object} scriptRecorder
 */
export const addScript = async (scriptRecorder = {}) => {
	const mutation = gql`
        mutation ($scriptRecorder: ScriptRecorderInput!) {
            AddScriptRecorder(
                ScriptRecorder: $scriptRecorder
            ) ${columns}
        }
    `;
	const {
		data: { AddScriptRecorder }
	} = await apollo.mutate({
		mutation,
		variables: {
			scriptRecorder
		},
		fetchPolicy: 'no-cache'
	});

	return AddScriptRecorder;
};

/**
 * add script
 * @param {Object} scriptRecorder
 */
export const updateScript = async (scriptId = 0, scriptRecorder = {}) => {
	const mutation = gql`
        mutation ($scriptId: ID!, $scriptRecorder: ScriptRecorderInput!) {
            UpdateScriptRecorder(
                ScriptRecorderId: $scriptId,
                UpdScriptRecorder: $scriptRecorder
            ) ${columns}
        }
    `;
	const {
		data: { UpdateScriptRecorder }
	} = await apollo.mutate({
		mutation,
		variables: {
			scriptId: parseInt(scriptId, 10),
			scriptRecorder
		},
		fetchPolicy: 'no-cache'
	});

	return UpdateScriptRecorder;
};

export const getOdooBackLog = async (projectId = null) => {
	if (_.isUndefined(projectId) || _.isNil(projectId)) {
		throw new Error('Invalid Project ID');
	}

	projectId = +projectId;

	const {
		data: { GetErrorLogFileOdoo }
	} = await apollo.query({
		query: gql`
			query ($projectId: ID!) {
				GetErrorLogFileOdoo(ProjectId: $projectId)
			}
		`,
		variables: {
			projectId
		},
		fetchPolicy: 'no-cache'
	});

	return GetErrorLogFileOdoo;
};

const columns = `{
    id
    projectId
    licenceId
    reference
    name
    type
    description
    templateImageChildXid
    outputNumber
	toDelete
	language
    scriptThumbImage {
        id
        xidThumb
    }
    scriptType
	license
	companyName
	projectName
	fileName
	outputFileFormat
	customExtension
	customExportFileName
	processNumber
	processingJsXid
	fileNameJs
}`;
