import gql from 'graphql-tag';
import { apolloClient as apollo } from '@/vue-apollo';
import _ from 'lodash';

export const purchaseOrderReport = async (filter, scriptId = null) => {
	const query = gql`
		query ($filter: AdvancedGlobalFilterInput, $scriptId: Int) {
			PoGridReportFromTo(Filter: $filter, ScriptId: $scriptId)
		}
	`;

	let variables = {
		filter
	};

	if (scriptId && +scriptId > 0) {
		variables = {
			...variables,
			scriptId: +scriptId
		};
	}

	const {
		data: { PoGridReportFromTo }
	} = await apollo.query({
		query,
		variables,
		fetchPolicy: 'no-cache'
	});

	return PoGridReportFromTo;
};

export const expenseReport = async (filter, scriptId = null) => {
	const query = gql`
		query ($filter: AdvancedGlobalFilterInput, $scriptId: Int) {
			ExpensesGridReportFromTo(Filter: $filter, ScriptId: $scriptId)
		}
	`;

	let variables = {
		filter
	};

	if (scriptId && +scriptId > 0) {
		variables = {
			...variables,
			scriptId: +scriptId
		};
	}

	const {
		data: { ExpensesGridReportFromTo }
	} = await apollo.query({
		query,
		variables,
		fetchPolicy: 'no-cache'
	});

	return ExpensesGridReportFromTo;
};

export const timeSheetReport = async (filter, scriptId = null) => {
	const query = gql`
		query ($filter: AdvancedGlobalFilterInput, $scriptId: Int) {
			TimesheetGridReportFromTo(Filter: $filter, ScriptId: $scriptId)
		}
	`;

	let variables = {
		filter
	};

	if (scriptId && +scriptId > 0) {
		variables = {
			...variables,
			scriptId: +scriptId
		};
	}

	const {
		data: { TimesheetGridReportFromTo }
	} = await apollo.query({
		query,
		variables,
		fetchPolicy: 'no-cache'
	});

	return TimesheetGridReportFromTo;
};

export const exportPayroll = async ({ startDate, endDate }) => {
	const MUTATION_EXPORT_PAYROLL = gql`
		mutation ($startDate: String!, $endDate: String!) {
			ExportPayCode(StrDate: $startDate, EndDate: $endDate)
		}
	`;

	const {
		data: { ExportPayCode }
	} = await apollo.mutate({
		mutation: MUTATION_EXPORT_PAYROLL,
		variables: {
			startDate,
			endDate
		}
	});

	return ExportPayCode;
};

export const userReport = async (filter, scriptId = null) => {
	const query = gql`
		query ($filter: AdvancedGlobalFilterInput, $scriptId: Int) {
			UserInfoGridReportFromTo(Filter: $filter, ScriptId: $scriptId)
		}
	`;

	let variables = {
		filter
	};

	if (scriptId && +scriptId > 0) {
		variables = {
			...variables,
			scriptId: +scriptId
		};
	}

	const {
		data: { UserInfoGridReportFromTo }
	} = await apollo.query({
		query,
		variables,
		fetchPolicy: 'no-cache'
	});

	return UserInfoGridReportFromTo;
};

export const expenseItemReport = async (filter, scriptId = null) => {
	const query = gql`
		query ($filter: AdvancedGlobalFilterInput, $scriptId: Int) {
			ExpenseItemGridReportFromTo(Filter: $filter, ScriptId: $scriptId)
		}
	`;

	let variables = {
		filter
	};

	if (scriptId && +scriptId > 0) {
		variables = {
			...variables,
			scriptId: +scriptId
		};
	}

	const {
		data: { ExpenseItemGridReportFromTo }
	} = await apollo.query({
		query,
		variables,
		fetchPolicy: 'no-cache'
	});

	return ExpenseItemGridReportFromTo;
};

export const jobRequestGridReportFromTo = async (jobRequestId, scriptId, sendMail = true, contactEmail = null) => {
	const query = gql`
		query ($jobRequestId: Int, $scriptId: ID!, $sendMail: Boolean!, $contactEmail: String) {
			JobRequestGridReportFromTo(Filter: { subjectId: $jobRequestId }, ScriptId: $scriptId, SendMail: $sendMail, ContactEmail: $contactEmail)
		}
	`;

	const {
		data: { JobRequestGridReportFromTo }
	} = await apollo.query({
		query,
		variables: {
			jobRequestId: +jobRequestId,
			scriptId: +scriptId,
			sendMail,
			contactEmail
		},
		fetchPolicy: 'no-cache'
	});

	return JobRequestGridReportFromTo;
};

export const budgetReport = async (
	{ forceCompute = false, categoryParentId = null, departmentId = -1, startDate = null, endDate = null, co2Category = null, sendMail = false },
	scriptId = null
) => {
	const query = gql`
		query (
			$forceCompute: Boolean
			$categoryParentId: ID
			$departmentId: ID
			$startDate: String
			$endDate: String
			$co2Category: ID
			$sendMail: Boolean
			$scriptId: Int
		) {
			BudgetReportFromTo(
				ForceCompute: $forceCompute
				CategoryParentId: $categoryParentId
				DepartmentNumber: $departmentId
				StrTime: $startDate
				EndTime: $endDate
				Co2Category: $co2Category
				SendMail: $sendMail
				ScriptId: $scriptId
			)
		}
	`;

	if (!_.isNil(categoryParentId) && !_.isUndefined(categoryParentId) && +categoryParentId > 0) {
		categoryParentId = +categoryParentId;
	}

	if (!_.isNil(departmentId) && !_.isUndefined(departmentId)) {
		departmentId = +departmentId;
	} else {
		departmentId = -1;
	}

	if (!_.isNil(co2Category) && !_.isUndefined(co2Category) && +co2Category > 0) {
		co2Category = +co2Category;
	}

	if (_.isUndefined(forceCompute) || _.isNil(forceCompute)) {
		forceCompute = true;
	}

	let variables = {
		forceCompute,
		categoryParentId,
		departmentId,
		startDate,
		endDate,
		co2Category,
		sendMail
	};

	if (!_.isNil(scriptId) && +scriptId > 0) {
		variables = {
			...variables,
			scriptId: +scriptId
		};
	}

	const {
		data: { BudgetReportFromTo }
	} = await apollo.query({
		query,
		variables,
		fetchPolicy: 'no-cache'
	});

	return BudgetReportFromTo;
};
